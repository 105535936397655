<template>
  <div>
    <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
      Control de temperaturas
    </div>

    <v-btn
      color="primary"
      class="ml-4 mr-4 mt-4"
      @click="nuevo"
      style="width:100px"
      >
      Nuevo
    </v-btn>

    <v-data-table
      :headers="headers"
      :items="dataRows"
      :options.sync="options"
      :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
      :server-items-length="totalDataRows"
      :loading="loading"
      style="overflow: scroll"
      mobile-breakpoint="0"
      class="elevation-1"
      dense
      @click:row="seleccion"
      item-key="CtrlId"
      >
    </v-data-table>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapState, mapMutations, mapActions } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz']),
        ...mapState('Ctrls', ['entidadActual'])
    },
    data () {
      return {
        totalDataRows: 0,
        dataRows: [],
        loading: true,
        options: {itemsPerPage: 15},
        headers: [
          { text: 'Fecha', width: 150, sortable: false, value: 'CtrlFechaStr' },
          { text: 'Tipo', width:300, sortable: false, value: 'CtrlCatNom' },
          { text: 'Realizado por', width: 250, class: 'col_para', sortable: false, value: 'PerNom' },
        ],
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
            .then(data => {
              this.dataRows = data.items
              this.totalDataRows =  data.total
            })
        },
        deep: true,
      },
    },
    mounted () {
      this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items
          this.totalDataRows = data.total
        })
    },
    methods: {
      ...mapMutations('Ctrls', ['entidadActualSet']),
      ...mapActions('Ctrls', ['nuevaEntidad']),
      seleccion(itm){ 
        this.entidadActualSet(itm);
        this.$router.push('/control-temperatura-edicion')
      },
      nuevo(){
        this.nuevaEntidad();
      },
      getDataFromApi () {
        this.loading = true
        return new Promise((resolve) => {
          const { sortBy, sortDesc } = this.options

          var NumRegsPag = this.options.itemsPerPage;
          var NumPag = this.options.page;
      
          var controllerParameters = {
                    Action: "GET_DATA_LIST_PAGINATED",   
                    NumRegsPag: NumRegsPag,
                    NumPag: NumPag,
                    EmpId: this.empId,
                    DirId: this.$store.state.dir.DirId,
                    CtrlCatIdParent: 1
                } ;       

          var AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/ctrl", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
            let items = result.data.EntsList;
            const total = result.data.EntsTotCount;

          if (sortBy.length === 1 && sortDesc.length === 1) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }

          setTimeout(() => {
            this.loading = false
            resolve({
              items,
              total,
            })
          }, 1000)
          });
        })
      },
    },
  }
</script>
<style>
  .col_para {
    min-width: 250px;
  }
</style>